<script>
import Vue from "vue";
import db from "@/db"; // Adjust this path to your db.js file location
import { mapState } from "vuex";

export default {
  name: "Data", // Replace with your actual component name
  data() {
    return {
      progress: 0,
      progressMessage: "",
    };
  },
  computed: {
    ...mapState(["apiUrl", "customerId"]),
  },
  mounted() {
    //localStorage.setItem("dbVersion", 0);
    window.addEventListener("online", this.handleOnlineStatus);

    this.$store.dispatch("loadData");
    this.updateDatabaseVersionCheck();
  },
  beforeDestroy() {
    window.removeEventListener("online", this.handleOnlineStatus);
  },
  methods: {
    handleOnlineStatus() {
      if (navigator.onLine) {
        // The browser has regained access to the network
        this.updateDatabaseVersionCheck();
      }
    },
    // Method to download an image and return its blob
    async downloadImageAsBlob(imageUrl) {
      try {
        const response = await fetch(imageUrl);
        if (!response.ok)
          throw new Error(`HTTP error! status: ${response.status}`);
        return await response.blob();
      } catch (error) {
        console.error("Failed to download image:", error);
        return null;
      }
    },
    // Method to insert or update an image blob in the database and return its ID
    async insertOrUpdateImageBlob(imageUrl, imageBlob) {
      try {
        let imageRecord = await db.images.where({ imageUrl }).first();
        if (imageRecord) {
          await db.images.update(imageRecord.id, { blob: imageBlob });
          return imageRecord.id;
        } else {
          const imageId = await db.images.add({ imageUrl, blob: imageBlob });
          return imageId;
        }
      } catch (error) {
        console.error("Error inserting or updating image blob:", error);
        return null;
      }
    },
    // Main method to process and insert data
    // In your data.vue component
    async insertData(jsonData) {
      const totalCards = jsonData.length;

      for (let i = 0; i < totalCards; i++) {
        const card = jsonData[i];

        this.$store.dispatch("updateProgress", {
          progress: ((i + 1) / totalCards) * 100,
          message: `Downloading cover image for Trail ${
            i + 1
          } of ${totalCards}`,
        });
        const coverImageBlob = await this.downloadImageAsBlob(card.CoverImage);

        this.$store.dispatch("updateProgress", {
          progress: ((i + 1) / totalCards) * 100,
          message: `Downloading elevation image for Trail ${
            i + 1
          } of ${totalCards}`,
        });
        const elevationImageBlob = await this.downloadImageAsBlob(
          card.ElevationProfile
        );

        this.$store.dispatch("updateProgress", {
          progress: ((i + 1) / totalCards) * 100,
          message: `Downloading POI images for Trail ${i + 1} of ${totalCards}`,
        });
        const poiImagesBlobs = await Promise.all(
          card.POI.map(poi => this.downloadImageAsBlob(poi.Image))
        );

        this.$store.dispatch("updateProgress", {
          progress: ((i + 1) / totalCards) * 100,
          message: `Downloading gallery images for Trail ${
            i + 1
          } of ${totalCards}`,
        });

        const galleryImagesIds = await Promise.all(
          card.Gallery.map(async galleryItem => {
            const galleryImageBlob = await this.downloadImageAsBlob(
              galleryItem.Url
            );
            return galleryImageBlob
              ? await this.insertOrUpdateImageBlob(
                  galleryItem.Url,
                  galleryImageBlob
                )
              : null;
          })
        );

        await db.transaction("rw", db.cards, db.pois, db.images, async () => {
          const coverImageId = coverImageBlob
            ? await this.insertOrUpdateImageBlob(
                card.CoverImage,
                coverImageBlob
              )
            : null;
          const elevationImageId = elevationImageBlob
            ? await this.insertOrUpdateImageBlob(
                card.ElevationProfile,
                elevationImageBlob
              )
            : null;

          card.Gallery.forEach((item, index) => {
            item.imageId = galleryImagesIds[index];
            delete item.Url;
          });

          // Update POIs with image IDs
          for (const [index, poi] of card.POI.entries()) {
            poi.imageId = poiImagesBlobs[index]
              ? await this.insertOrUpdateImageBlob(
                  poi.Image,
                  poiImagesBlobs[index]
                )
              : null;
          }

          const cardDataWithBlobReferences = {
            ...card,
            CoverImage: coverImageId,
            ElevationProfile: elevationImageId,
            Gallery: card.Gallery,
            POI: card.POI,
          };

          const cardId = await db.cards.add(cardDataWithBlobReferences);

          for (const poi of card.POI) {
            const exists = await db.pois.get(poi.MarkerId);
            if (!exists) {
              await db.pois.put({
                ...poi,
                rootId: cardId,
              });
            }
          }
        });

        this.$store.dispatch("updateProgress", {
          progress: ((i + 1) / totalCards) * 100,
          message: `Processing card ${i + 1} of ${totalCards}`,
        });
      }

      this.$store.dispatch("updateProgress", {
        progress: 100,
        message: "Data loading complete!",
      });
      this.$store.commit("setDataLoaded", true);

      // Reload the page after data is completely processed
      location.reload();
    },
    getStoredVersion() {
      const version = localStorage.getItem("dbVersion");
      return version === null ? 0 : parseInt(version, 10);
    },
    updateStoredVersion(version) {
      localStorage.setItem("dbVersion", version.toString());
    },
    async countCardEntries() {
      return db.cards.count(); // Assuming 'cards' is your table in IndexedDB
    },
    async updateDatabaseVersionCheck() {
      const url = this.apiUrl + "VisitorCard/GetVersion";
      const base64Credentials = btoa("Ian:Ennistymon1!");
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            CustomerId: this.customerId,
            Authorization: `Basic ${base64Credentials}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const dbVersion = await response.json();

        const newVersion = dbVersion;
        const storedVersion = this.getStoredVersion();
        const cardCount = await this.countCardEntries();

        if (newVersion > storedVersion || cardCount === 0) {
          console.log("New version available, updating local database...");
          await this.updateDatabase();
          this.updateStoredVersion(newVersion);
        } else {
          console.log("Using stored data, no update needed.");
        }
      } catch (error) {
        console.error("Error fetching version or updating database:", error);
      }
    },
    async updateDatabase() {
      const url = this.apiUrl + "VisitorCard/GetCards";
      const base64Credentials = btoa("Ian:Ennistymon1!");
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            CustomerId: this.customerId,
            Authorization: `Basic ${base64Credentials}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const jsonData = await response.json();
        await this.deleteAndRecreateDB(jsonData);
      } catch (error) {
        console.error("Failed to load and insert data:", error);
      }
    },
    async deleteAndRecreateDB(jsonData) {
      console.log("Clearing database tables...");
      try {
        await db.transaction("rw", db.cards, db.pois, db.images, async () => {
          await db.cards.clear();
          await db.pois.clear();
          await db.images.clear();
        });

        console.log("Database tables cleared, inserting new data...");
        await this.insertData(jsonData);
      } catch (error) {
        console.error(
          "Error clearing database tables or inserting data:",
          error
        );
      }
    },
  },
};
</script>
