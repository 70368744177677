<template>
  <div id="map" ref="mapContainer" style="height: 100vh; width: 100%"></div>
  <div
    id="follow-me-button"
    :class="{ following: isFollowing }"
    @click="toggleFollowMe"
  >
    <img
      v-show="isFollowing"
      width="30px"
      :src="`/img/location_crosshairs_icon_1.png`"
    />
    <img
      v-show="!isFollowing"
      width="30px"
      :src="`/img/location_crosshairs_icon_2.png`"
    />
  </div>

  <div
    class="modal fade"
    id="noEmailModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Notification</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          You are not registered for Rewards for this walk.
          <br /><br />
          <button
            type="button"
            class="btn btn-primary"
            style="width: 300px"
            @click="redirectToRegister"
          >
            Register here
          </button>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Continue unregistered
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-rotatedmarker";
import arrowIcon from "@/assets/arrow.svg"; // Importing the SVG
import start from "@/assets/start.png";
import stop from "@/assets/stop.png";
import pinRed from "@/assets/location-dot-solid-Red.svg";
import pinBlue from "@/assets/location-dot-solid-Blue.svg";
import db from "@/db"; // Importing the Dexie database

export default {
  name: "MapComponent",
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      map: null,
      marker: null,
      watchId: null,
      currentLocation: null,
      previousLocation: null,
      currentHeading: null,
      startMarker: null,
      endMarker: null,
      polyline: null,
      movingIcon: null,
      polylineData: [],
      poiData: [],
      StartLocationLat: null,
      StartLocationLng: null,
      EndLocationLat: null,
      EndLocationLng: null,
      isBadgeRegistered: false,
      isFollowing: true,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  created() {
    // Ensure isBadgeRegistered is false when the component is created
    this.isBadgeRegistered = false;
  },
  async mounted() {
    await this.initializeMap();
    await this.loadCardData();
    this.isBadgeRegistered = false;
    // Check if 'userEmail' is not in localStorage
    if (!localStorage.getItem("userEmail")) {
      // Trigger the Bootstrap modal
      this.showModal();
    }
  },
  beforeUnmount() {
    if (this.map) {
      this.map.remove();
    }
    this.isBadgeRegistered = false;
  },
  methods: {
    redirectToRegister() {
      // Close the modal
      $("#noEmailModal").modal("hide");

      // Redirect after a slight delay to ensure the modal is closed
      setTimeout(() => {
        this.$router.push("/register/0");
      }, 1000); // 300ms should be enough time to ensure the modal has fully closed
    },
    showModal() {
      $("#noEmailModal").modal("show");
    },
    async initializeMap() {
      this.$nextTick(() => {
        const mapElement = this.$refs.mapContainer;
        if (!mapElement) {
          console.error("Map container not found");
          return;
        }
        const initialCoords = [53, -9.09]; // Default to London, adjust as necessary

        // Initialize the map
        var mapOptions = {
          zoomAnimation: false,
          markerZoomAnimation: false,
        };
        this.map = L.map(mapElement, mapOptions).setView(
          [52.9146364, -6.2250095],
          15
        );

        // Add OpenStreetMap tiles
        L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
          attribution:
            '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        }).addTo(this.map);

        // Create the custom icon
        this.movingIcon = L.icon({
          iconUrl: arrowIcon,
          iconSize: [30, 30], // Adjust size as needed
          iconAnchor: [15, 15], // Adjust anchor to center the icon
        });

        // Create a marker with the custom icon and rotation
        this.marker = L.marker(initialCoords, {
          icon: this.movingIcon,
          rotationAngle: 0,
          rotationOrigin: "center",
        }).addTo(this.map);

        //FOLLOW ME ACTION
        this.map.on("dragend", () => {
          const followMeButton = L.DomUtil.get("follow-me-button");
          if (this.isFollowing) {
            this.isFollowing = false;
            followMeButton.innerHTML =
              followMeButton.innerHTML = `<img width="30px" src="/img/location_crosshairs_icon_2.png">`;
          }
        });

        // Start watching the user's location
        this.startLocationWatch();
      });
    },
    async loadCardData() {
      try {
        const card = await db.cards.get({ CardId: this.id }); // Adjust ID as necessary

        this.isBadgeRegistered = false;
        if (card) {
          //this.StartLocationLat = card.StartLocationLat;
          //this.StartLocationLng = card.StartLocationLng;
          //this.EndLocationLat = card.EndLocationLat;
          //this.EndLocationLng = card.EndLocationLng;

          this.polylineData = card.Polyline.map(point => [
            parseFloat(point.lat),
            parseFloat(point.lng),
          ]);
          this.poiData = card.POI || [];
          this.addPolylineAndMarkers();
          this.addPOIMarkers();
        }
      } catch (error) {
        console.error("Error fetching card data:", error);
      }
    },
    async registeredBadges() {
      if (!this.currentLocation) {
        console.error("Current location is not available.");
        return;
      }
      const userLat = this.currentLocation.lat;
      const userLng = this.currentLocation.lng;
      var test = this.polylineData[0][0];
      const distanceStart = this.calculateDistance(
        this.polylineData[0][0],
        this.polylineData[0][1],
        userLat,
        userLng
      );

      const lastIndex = this.polylineData.length - 1;
      const lastLat = this.polylineData[lastIndex][0];
      const lastLng = this.polylineData[lastIndex][1];
      const distanceEnd = this.calculateDistance(
        lastLat,
        lastLng,
        userLat,
        userLng
      );
      const within1km = distanceStart <= 1;
      const within1kmEnd = distanceEnd <= 1;

      const badgeData = {
        cardId: this.id,
        email: localStorage.getItem("userEmail"),
        start: true,
        start1km: within1km,
        end1km: within1kmEnd,
        longitude: this.currentLocation.lng,
        latitude: this.currentLocation.lat,
        timestamp: new Date().toISOString(),
      };

      try {
        await this.sendBadgeToAPI(badgeData);
        alert("Badge registered successfully!");
      } catch (error) {
        console.error("Error sending badge to API:", error);
        try {
          await db.badges.add(badgeData);
          this.registerBackgroundSync();
          alert("Badge saved locally and will be synced later.");
        } catch (dbError) {
          console.error("Error saving badge to IndexedDB:", dbError);
        }
      }

      //get users position
      //get distance from start lat lng
      // assemble data
      //data is like
      //id, cardId, email from localStorage, true, and true if user position is within 1km of the polyline start position
    },
    addPolylineAndMarkers() {
      if (this.polylineData.length > 0) {
        // Add polyline
        this.polyline = L.polyline(this.polylineData, { color: "blue" }).addTo(
          this.map
        );
        console.log(this.polylineData[0][0]);
        // Fit map bounds to polyline
        this.map.fitBounds(this.polyline.getBounds());

        // Add start marker
        //const startCoords = [this.StartLocationLat, this.StartLocationLng];
        const startCoords = [
          parseFloat(this.polylineData[0][0]),
          parseFloat(this.polylineData[0][1]),
        ];

        this.startMarker = L.marker(startCoords, {
          icon: L.icon({
            iconUrl: start, // Provide the correct path to your start icon
            iconSize: [30, 30], // Adjust size as needed
            iconAnchor: [15, 15], // Adjust anchor to center the icon
          }),
        }).addTo(this.map);

        // Add end marker
        //const endCoords = [this.EndLocationLat, this.EndLocationLng];
        const endCoords = [
          parseFloat(
            parseFloat(this.polylineData[this.polylineData.length - 1][0])
          ),
          parseFloat(
            parseFloat(this.polylineData[this.polylineData.length - 1][1])
          ),
        ];

        this.endMarker = L.marker(endCoords, {
          icon: L.icon({
            iconUrl: stop, // Provide the correct path to your end icon
            iconSize: [30, 30], // Adjust size as needed
            iconAnchor: [15, 15], // Adjust anchor to center the icon
          }),
        }).addTo(this.map);
      }
    },
    addPOIMarkers() {
      console.log(this.poiData);
      this.poiData.forEach(poi => {
        const coords = [parseFloat(poi.Latitude), parseFloat(poi.Longitude)];
        const iconUrl = poi.TypeId === "1" ? pinRed : pinBlue; // Different icons based on TypeId
        const theType = poi.TypeId === "1" ? "POI" : "SERVICE";
        const theColour = poi.TypeId === "1" ? "#ff0000" : "#0000ff";
        const icon = L.divIcon({
          html: `<img src="${iconUrl}" alt="Icon" style="width: 30px; height: 30px;">
                 <img src="${poi.Image}" alt="Thumbnail" style="width: 60px; height: 60px; position: absolute; top: -65px; left: -18px;border:3px solid white;">`,
          iconSize: [30, 30],
          className: "", // Remove default leaflet styling
        });

        L.marker(coords, { icon })
          .addTo(this.map)
          .bindPopup(
            `<h3 style="text-align:center;color:${theColour}">${theType}</h3><b><h5 style="text-align:center;">${poi.Title}</h5></b><br><img src="${poi.Image}" style="width:300px;"/><br><br>${poi.Body}`
          );
      });
    },
    //TRACKING
    startLocationWatch() {
      if ("geolocation" in navigator) {
        this.watchId = navigator.geolocation.watchPosition(
          this.updateLocation,
          this.handleLocationError,
          { enableHighAccuracy: true }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    },
    updateLocation(position) {
      if (!position || !position.coords) {
        console.error("Position or coordinates are not available");
        return;
      }

      const { latitude, longitude } = position.coords;
      if (typeof latitude !== "number" || typeof longitude !== "number") {
        console.error("Invalid latitude or longitude values");
        return;
      }

      const newLatLng = new L.LatLng(latitude, longitude);

      if (this.previousLocation) {
        // Calculate bearing
        const heading = this.calculateBearing(this.previousLocation, newLatLng);
        console.log(`Calculated heading: ${heading}`);

        // Smooth the heading
        this.currentHeading = this.smoothHeading(this.currentHeading, heading);
        console.log(`Smoothed heading: ${this.currentHeading}`);

        // Ensure the marker is initialized before updating rotation
        if (this.marker) {
          this.marker.setRotationAngle(this.currentHeading);
        } else {
          console.error("Marker is not initialized");
        }
      }

      this.previousLocation = newLatLng;
      this.currentLocation = newLatLng;

      // Ensure the marker and map are initialized before interacting with them
      if (this.marker) {
        this.marker.setLatLng(newLatLng);
      } else {
        console.error("Marker is not initialized");
      }

      if (!this.map) {
        console.error("Map is not initialized");
      } else if (!this.isValidLatLng(newLatLng)) {
        console.error("newLatLng is not defined or invalid");
      } else {
        if (this.isFollowing) {
          this.map.panTo(newLatLng);
        }
      }

      if (localStorage.getItem("userEmail") && !this.isBadgeRegistered) {
        // Check if polylineData is available
        if (this.polylineData && this.polylineData.length > 0) {
          // Registered for badges
          this.registeredBadges();
          this.isBadgeRegistered = true; // Ensure this part runs only once
        } else {
          console.log("Waiting for polylineData to be available...");
        }
      }

      // Ensure the map is initialized before setting the view
      if (this.isFollowing && this.map) {
        this.map.setView(newLatLng); // Use newLatLng instead of latlng
      } else if (!this.map) {
        console.error("Map is not initialized");
      }
    },

    handleLocationError(error) {
      console.error("Location error:", error);
    },
    isValidLatLng(latlng) {
      return (
        latlng &&
        typeof latlng.lat === "number" &&
        typeof latlng.lng === "number" &&
        latlng.lat >= -90 &&
        latlng.lat <= 90 &&
        latlng.lng >= -180 &&
        latlng.lng <= 180
      );
    },
    toggleFollowMe() {
      const followMeButton = L.DomUtil.get("follow-me-button");
      this.isFollowing = !this.isFollowing;
      if (this.isFollowing) {
        followMeButton.innerHTML = `<img width="30px" src="/img/location_crosshairs_icon_1.png">`;
      } else {
        followMeButton.innerHTML = `<img width="30px" src="/img/location_crosshairs_icon_2.png">`;
      }

      if (this.isFollowing) {
        if (this.currentLocation) {
          this.map.setView(this.currentLocation);
        }
      }
    },
    calculateDistance(lat1, lon1, lat2, lon2) {
      const R = 6371; // Radius of the Earth in kilometers
      const dLat = (lat2 - lat1) * (Math.PI / 180);
      const dLon = (lon2 - lon1) * (Math.PI / 180);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * (Math.PI / 180)) *
          Math.cos(lat2 * (Math.PI / 180)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = R * c; // Distance in kilometers
      return distance;
    },
    calculateBearing(start, end) {
      const lat1 = start.lat * (Math.PI / 180);
      const lon1 = start.lng * (Math.PI / 180);
      const lat2 = end.lat * (Math.PI / 180);
      const lon2 = end.lng * (Math.PI / 180);

      const y = Math.sin(lon2 - lon1) * Math.cos(lat2);
      const x =
        Math.cos(lat1) * Math.sin(lat2) -
        Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1);
      const bearing = Math.atan2(y, x) * (180 / Math.PI);
      return (bearing + 360) % 360; // Normalize to 0-360 degrees
    },
    smoothHeading(previousHeading, newHeading) {
      if (previousHeading === null) return newHeading;
      const alpha = 0.2; // Smoothing factor, adjust as needed

      // Calculate the difference
      let delta = newHeading - previousHeading;

      // Ensure the shortest path is taken
      if (delta > 180) delta -= 360;
      if (delta < -180) delta += 360;

      const smoothedHeading = previousHeading + alpha * delta;
      return (smoothedHeading + 360) % 360; // Normalize to 0-360 degrees
    },
    async sendBadgeToAPI(badgeData) {
      debugger;
      const base64Credentials = btoa("Ian:Ennistymon1!");
      const response = await fetch(
        "https://live-api-v2.waywyser.com/api/VisitorCard/ReportBadge",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            CustomerId: localStorage.getItem("tenantId"), // Replace with actual CustomerId
            Authorization: `Basic ${base64Credentials}`,
          },
          body: JSON.stringify(badgeData),
        }
      );

      if (!response.ok) {
        if (response.status === 409) {
          // Handle duplicate case
          alert("It appears this badge has already been created.");
        } else {
          throw new Error("Failed to send badge to API");
        }
      }
    },
    async registerBackgroundSync() {
      if ("serviceWorker" in navigator && "SyncManager" in window) {
        try {
          const registration = await navigator.serviceWorker.ready;
          await registration.sync.register("sync-badges");
        } catch (error) {
          console.error("Error registering background sync:", error);
        }
      } else {
        console.warn("Background sync is not supported in this browser.");
      }
    },
  },
};
</script>

<style scoped>
#map {
  height: 100vh;
  width: 100%;
  top: -60px;
}

#follow-me-button {
  position: absolute;
  top: 230px;
  left: 8px;
  z-index: 1000;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#follow-me-button i {
  font-size: 24px;
}

#follow-me-button.following {
  background-color: #ffffff;
  color: white;
}
</style>
