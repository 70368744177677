<template>
  <button class="btn btn-primary back-button" @click="goBack">← Back</button>
  <!-- About Start -->
  <div v-if="card" class="container-fluid">
    <div class="container pt-1">
      <div class="row justify-content-center">
        <div class="col-lg-12 text-center">
          <div
            class="buttonsx"
            style="position: Relative; top: -20px; font-size: 11px"
          >
            <buttonx :class="{ active: registered }" @click="registerForBadges">
              <span v-html="firstButtonText"></span>
            </buttonx>

            <buttonx
              :class="{ active: !registered }"
              @click="unRegisterForBadges"
            >
              {{ secondButtonText }}
            </buttonx>
            <button
              type="button"
              class="btn btn-secondary"
              data-toggle="popover"
              data-trigger="focus"
              title="Information"
              data-html="true"
              data-content="As an outdoor recreation incentive, Register to claim points and reclaim for free gifts. see <a href='https://www.wicklowWebsiteHere.com' target='_blank'>wicklowWebsiteHere.com</a> for details"
            >
              <i class="fa fa-question-circle"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6" style="min-height: 500px">
          <div class="position-relative h-100">
            <ImageComponentAnimate
              @click="async () => handleImageClick(card.CoverImage)"
              v-if="card.CoverImage"
              :imageId="card.CoverImage"
            ></ImageComponentAnimate>
            <div class="card-info">
              <div class="grade">
                <small class="text-white text-uppercase">
                  <i class="fa fa-shoe-prints" style="font-size: 1.4rem"></i
                  ><span style="margin-left: 5px">{{ card.Grading }}</span>
                </small>
              </div>
              <div class="time">
                <small class="text-white text-uppercase">
                  <i class="fa fa-clock" style="font-size: 1.4rem"></i
                  ><span style="margin-left: 5px">{{
                    formatTime(card.Duration)
                  }}</span>
                </small>
              </div>
              <div class="length">
                <small class="text-white text-uppercase">
                  <i class="fa fa-map-pin" style="font-size: 1.4rem"></i
                  ><span style="margin-left: 5px">{{ card.Distance }} km</span>
                </small>
              </div>

              <div class="type">
                <small class="text-white text-uppercase">
                  <i class="fa fa-sign" style="font-size: 1.4rem"></i
                  ><span style="margin-left: 5px">{{ card.ShapeName }}</span>
                </small>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6 pt-2 pb-lg-5">
          <div class="about-text bg-white p-4 p-lg-5 my-lg-5">
            <h2 class="mb-3">
              {{ card.Name }}
            </h2>
            <h5 class="mb-3 text-primary">
              {{ card.SubName }}
            </h5>

            <div class="waymark">
              <span class="text-dark">Waymarking Colour</span>
              <span class="badge badge-secondary badge-pill badge-content">
                <div
                  class="color-box mr-4"
                  :style="{
                    backgroundColor: card.Prominence.toLowerCase(),
                  }"
                ></div>
                {{ card.Prominence }}
              </span>
            </div>

            <div
              class="chart-container"
              style="position: relative; margin-top: 20px; margin-bottom: 20px"
            >
              <canvas id="elevationChart"></canvas>
            </div>

            <p>
              {{ card.ShortDescription }}
            </p>
            <div class="row mb-4">
              <div class="col-12">
                <ImageComponent
                  v-if="
                    card.Gallery &&
                    card.Gallery.length > 0 &&
                    card.Gallery[0].imageId
                  "
                  :imageId="card.Gallery[0].imageId"
                  @click="async () => handleImageClick(card.Gallery[0].imageId)"
                ></ImageComponent>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- About End -->

  <div class="container-fluid">
    <div v-if="card" class="container" style="margin-top: 20px">
      <div class="row">
        <div class="col-lg-12">
          <!-- Blog Detail Start -->
          <div class="pb-3">
            <div class="blog-item">
              <div class="position-relative">
                <ImageComponent
                  v-if="
                    card.Gallery &&
                    card.Gallery.length > 1 &&
                    card.Gallery[1].imageId
                  "
                  :imageId="card.Gallery[1].imageId"
                  @click="async () => handleImageClick(card.Gallery[1].imageId)"
                ></ImageComponent>
              </div>
            </div>
            <div class="bg-white mb-3" style="padding: 30px">
              <h4 class="mb-3" style="margin-top: 15px">General Description</h4>
              <p>
                {{ card.GeneralDescription }}
              </p>

              <p>
                <!--  {{ card.RouteDescription }} -->
              </p>

              <ImageComponent
                v-if="
                  card.Gallery &&
                  card.Gallery.length > 2 &&
                  card.Gallery[2].imageId
                "
                :imageId="card.Gallery[2].imageId"
                @click="async () => handleImageClick(card.Gallery[2].imageId)"
              ></ImageComponent>

              <hr
                v-if="
                  card.Gallery &&
                  card.Gallery.length > 2 &&
                  card.Gallery[2].imageId
                "
              />
              <ImageComponent
                v-if="
                  card.Gallery &&
                  card.Gallery.length > 3 &&
                  card.Gallery[3].imageId
                "
                :imageId="card.Gallery[3].imageId"
                @click="async () => handleImageClick(card.Gallery[3].imageId)"
              ></ImageComponent>
              <hr
                v-if="
                  card.Gallery &&
                  card.Gallery.length > 3 &&
                  card.Gallery[3].imageId
                "
              />
              <ImageComponent
                v-if="
                  card.Gallery &&
                  card.Gallery.length > 4 &&
                  card.Gallery[4].imageId
                "
                :imageId="card.Gallery[4].imageId"
                @click="async () => handleImageClick(card.Gallery[4].imageId)"
              ></ImageComponent>
              <hr
                v-if="
                  card.Gallery &&
                  card.Gallery.length > 4 &&
                  card.Gallery[4].imageId
                "
              />
            </div>
          </div>
          <!-- Blog Detail End -->

          <!-- Info PArt -->

          <!-- Category List -->
          <div class="mb-5">
            <div class="bg-white p-3">
              <div class="category-grid">
                <div class="category-item">
                  <span class="text-dark">Dogs Allowed</span>
                  <span class="badge badge-secondary badge-pill badge-content">
                    <span v-if="card.DogsAllowed">Yes</span>
                    <span v-else>No</span>
                  </span>
                </div>
                <div class="category-item" @click="toggleCarPark">
                  <span class="text-dark">
                    <i
                      :class="[
                        'fa',
                        showCarPark ? 'fa-angle-down' : 'fa-angle-right',
                        'text-primary',
                        'mr-2',
                      ]"
                    ></i>
                    Carpark
                  </span>
                  <span class="badge badge-primary badge-pill badge-content">
                    <span v-if="showCarPark">{{ card.NearestCarpark }}</span>
                  </span>
                </div>
                <div class="category-item" @click="toggleSafety">
                  <span class="text-dark">
                    <i
                      :class="[
                        'fa',
                        showSafety ? 'fa-angle-down' : 'fa-angle-right',
                        'text-primary',
                        'mr-2',
                      ]"
                    ></i>
                    Safety
                  </span>
                  <span class="badge badge-primary badge-pill badge-content">
                    <span v-if="showSafety">{{ card.Safety }}</span>
                  </span>
                </div>
                <div class="category-item" @click="toggleGear">
                  <span class="text-dark">
                    <i
                      :class="[
                        'fa',
                        showGear ? 'fa-angle-down' : 'fa-angle-right',
                        'text-primary',
                        'mr-2',
                      ]"
                    ></i>
                    Gear
                  </span>
                  <span class="badge badge-primary badge-pill badge-content">
                    <span v-if="showGear">{{ card.Gear }}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="fixed-grid">
        <div class="fixed-grid-item" v-if="card" :key="card.CardId">
          <div
            class="service-item bg-white text-center mb-2 py-3"
            @click="
              openGoogleMaps(card.StartLocationLat, card.StartLocationLng)
            "
            style="cursor: pointer"
          >
            <i class="fa fa-2x fa-route mx-auto mb-4"></i>
            <h5 class="mb-2">TRAIL HEAD</h5>
            <p class="m-0">Open in Google Maps</p>
          </div>
        </div>

        <div class="fixed-grid-item" v-if="card" :key="card.CardId">
          <router-link
            :to="'/map/' + card.CardId"
            style="text-decoration: none; color: inherit"
            @click.native="saveScrollPosition"
          >
            <div class="service-item bg-white text-center mb-2 py-3">
              <i class="fa fa-2x fa-play mx-auto mb-4"></i>
              <h5 class="mb-2">START NOW</h5>
              <p class="m-0"></p>
            </div>
          </router-link>
        </div>

        <div class="fixed-grid-item" v-if="card" :key="card.CardId">
          <router-link
            :to="'/services/' + card.CardId"
            style="text-decoration: none; color: inherit"
            @click.native="saveScrollPosition"
          >
            <div class="service-item bg-white text-center mb-2 py-3">
              <i class="fa fa-2x fa-concierge-bell mx-auto mb-4"></i>
              <h5 class="mb-2">NEAR BY SERVICES</h5>
              <p class="m-0"></p>
            </div>
          </router-link>
        </div>

        <div class="fixed-grid-item" v-if="card" :key="card.CardId">
          <router-link
            :to="'/pois/' + card.CardId"
            style="text-decoration: none; color: inherit"
            @click.native="saveScrollPosition"
          >
            <div class="service-item bg-white text-center mb-2 py-3">
              <i class="fa fa-2x fa-map-marker mx-auto mb-4"></i>
              <h5 class="mb-2">NEAR BY POIs</h5>
              <p class="m-0"></p>
            </div>
          </router-link>
        </div>
        <div class="fixed-grid-item" :key="card.CardId">
          <router-link
            :to="'/video/' + card.CardId"
            style="text-decoration: none; color: inherit"
            :class="{ 'disabled-link': !card.VideoLink }"
            :tabindex="card.VideoLink ? 0 : -1"
          >
            <div
              class="service-item bg-white text-center mb-2 py-3"
              :style="{
                color: card.VideoLink ? 'inherit' : '#aaa',
                cursor: card.VideoLink ? 'pointer' : 'not-allowed',
              }"
            >
              <i class="fa fa-2x fa-map-marker mx-auto mb-4"></i>
              <h5 class="mb-2">Video Overview</h5>
              <p class="m-0"></p>
            </div>
          </router-link>
        </div>

        <div class="fixed-grid-item" v-if="card" :key="card.CardId">
          <router-link
            :to="'/issue/' + card.CardId"
            style="text-decoration: none; color: inherit"
            @click.native="saveScrollPosition"
          >
            <div class="service-item bg-white text-center mb-2 py-3">
              <i class="fa fa-2x fa-flag mx-auto mb-4"></i>
              <h5 class="mb-2">Report Issue</h5>
              <p class="m-0"></p>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <!-- Blog End -->

  <!-- Vue Easy Lightbox -->
  <vue-easy-lightbox
    :visible="visible"
    :imgs="[currentImage]"
    :index="index"
    @hide="visible = false"
  ></vue-easy-lightbox>
</template>

<script>
import db from "@/db";
import LeafletMap from "@/components/LeafletMap.vue";
import ImageComponent from "@/components/ImageComponent.vue";
import ImageComponentAnimate from "@/components/ImageComponentAnimate.vue";
import VueEasyLightbox from "vue-easy-lightbox";
import { Chart, registerables } from "chart.js";
import { resolveDirective } from "vue";
Chart.register(...registerables);

export default {
  components: {
    VueEasyLightbox,
    LeafletMap,
    ImageComponent,
    ImageComponentAnimate,
  },
  data() {
    return {
      card: null,
      showSafety: false,
      showGear: false,
      showCarPark: false,
      // Lightbox related data
      visible: false,
      index: 0,
      currentImage: "",
      registered: false,
      polyline: null,
    };
  },
  created() {
    this.checkRegistrationStatus();
  },
  async mounted() {
    this.getData();

    await this.$nextTick(); // W
    setTimeout(() => {
      window.scrollTo(0, 500);
    }, 100); // Adjust the delay time if needed

    // Restore the scroll position if it exists
    const savedPosition = sessionStorage.getItem("scrollPosition");
    //console.log("scrlPos " + savedPosition);
    if (savedPosition) {
      // Use nextTick to ensure the DOM is fully loaded before scrolling
      this.$nextTick(() => {
        setTimeout(() => {
          window.scrollTo(0, parseInt(savedPosition, 10));
        }, 100);
        sessionStorage.removeItem("scrollPosition"); // Clean up the saved position
      });
    } else {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    }

    console.log("Initializing popover");
    $(function () {
      $('[data-toggle="popover"]').popover();
    });
  },
  computed: {
    firstButtonText() {
      return this.registered
        ? "Registered for Badges  <i class='fa fa-check'></i>"
        : "Register for Badges";
    },
    secondButtonText() {
      return this.registered
        ? "Unregister for Badges"
        : "UnRegistered for Badges";
    },
    hasThirdImage() {
      return (
        this.card.Gallery &&
        this.card.Gallery.length > 2 &&
        this.card.Gallery[2].imageId
      );
    },
    hasFourthImage() {
      return (
        this.card.Gallery &&
        this.card.Gallery.length > 3 &&
        this.card.Gallery[3].imageId
      );
    },
    hasFifthImage() {
      return (
        this.card.Gallery &&
        this.card.Gallery.length > 4 &&
        this.card.Gallery[4].imageId
      );
    },
  },
  methods: {
    saveScrollPosition() {
      const scrollPosition = window.scrollY;
      sessionStorage.setItem("scrollPosition", scrollPosition);
    },
    async getData() {
      try {
        // Fetch card data based on the CardId parameter
        const cardId = parseInt(this.$route.params.CardId); // Convert to integer if necessary
        this.card = null;
        var cardArray = await db.cards.where("CardId").equals(cardId).toArray();
        this.card = cardArray[0];
        this.polyline = cardArray[0].Polyline;

        this.createElevationChart();
      } catch (error) {
        console.error("Error fetching card data:", error);
      }
    },
    checkRegistrationStatus() {
      this.registered = !!localStorage.getItem("userEmail");
    },
    registerForBadges() {
      this.$router.push({
        name: "register",
        params: { CardId: this.card.CardId },
      });
    },
    unRegisterForBadges() {
      localStorage.removeItem("userEmail");
      this.registered = !!localStorage.getItem("userEmail");
    },
    toggleSafety() {
      this.showSafety = !this.showSafety;
    },
    toggleGear() {
      this.showGear = !this.showGear;
    },
    toggleCarPark() {
      this.showCarPark = !this.showCarPark;
    },
    openGoogleMaps(lat, lng) {
      // Construct Google Maps URL
      const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;

      // Open Google Maps URL
      window.open(googleMapsUrl, "_blank");
    },
    formatTime(minutes) {
      const days = Math.floor(minutes / 1440);
      const hours = Math.floor((minutes % 1440) / 60);
      const mins = minutes % 60;
      let formattedTime = "";

      if (days > 0) {
        formattedTime += `${days} day${days > 1 ? "s" : ""}`;
      }

      if (hours > 0) {
        if (formattedTime) formattedTime += " ";
        formattedTime += `${hours} hr${hours > 1 ? "s" : ""}`;
      }

      if (mins > 0) {
        if (formattedTime) formattedTime += " ";
        formattedTime += `${mins} min${mins > 1 ? "s" : ""}`;
      }

      return formattedTime || "0 mins";
    },
    async handleImageClick(imageId) {
      debugger;
      try {
        const imageUrl = await this.getImageUrl(imageId);
        console.log("Image clicked, URL:", imageUrl);
        this.currentImage = imageUrl;
        this.visible = true;
      } catch (error) {
        console.error("Failed to handle image click:", error);
      }
    },

    async getImageUrl(imageId) {
      if (!imageId) return "";
      try {
        const imageRecord = await db.images.get(imageId);
        const blobUrl = imageRecord
          ? URL.createObjectURL(imageRecord.blob)
          : "";
        console.log("Generated Blob URL:", blobUrl); // Log Blob URL to verify
        return blobUrl;
      } catch (error) {
        console.error("Failed to fetch image:", error);
        return "";
      }
    },
    goBack() {
      this.$router.go(-1); // Go back to the previous page
    },
    createElevationChart() {
      const ctx = document.getElementById("elevationChart").getContext("2d");

      // Process polyline data to get distances (in km), elevations, and total ascent
      const data = this.processPolylineData();

      const gradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);

      // Add color stops for gradient transition
      gradient.addColorStop(0, "rgba(0, 20, 0, 1)"); // Dark green
      gradient.addColorStop(0.5, "rgba(0, 100, 0, 1)"); // Medium green
      gradient.addColorStop(1, "rgba(0, 255, 0, 1)"); // Light green

      // Define chart with a plugin for the light blue background
      const chart = new Chart(ctx, {
        type: "line",
        data: {
          labels: data.distances, // Distances are now in kilometers
          datasets: [
            {
              label: `Total Ascent: ${data.totalAscent} m`,
              borderColor: "rgba(255, 255, 255, 1)",
              borderWidth: 6,
              data: data.elevations,
              fill: true,
              backgroundColor: gradient,
              pointRadius: 0, // No dots on line
              tension: 0.3, // Straight or curved line (0 - 1)
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              labels: {
                usePointStyle: true, // Use a point instead of the box
                pointStyle: "line", // Make the point style to be like a line
              },
            },
          },
          scales: {
            y: {
              grid: {
                display: false, // Hides the grid lines for the y-axis
              },
              title: {
                display: true,
                text: "Total Ascent (m)",
              },
              min: 0, // Set minimum y-axis value
              max: 1100, // Set maximum y-axis value
            },
            x: {
              type: "linear", // Ensures the x-axis is treated as linear
              ticks: {
                stepSize: 1, // Display a tick every 0.5 km
                callback: function (value, index) {
                  const roundedValue = Math.round(value * 10) / 10; // Keep one decimal place for km
                  return `${roundedValue} km`; // Display distance in kilometers
                },
              },
              grid: {
                display: false, // Hides the grid lines for the x-axis
              },
              title: {
                display: true,
                text: "Distance (km)", // Update x-axis label to show "Distance (km)"
              },
            },
          },
        },
      });

      // Now that the chart is created, update its datasets to ensure the fill is applied
      chart.update();
    },

    processPolylineData() {
      // Convert distances from meters to kilometers
      const distances = this.polyline.map(
        point => point.distanceFromStart / 1000
      );
      const elevations = this.polyline.map(point => point.height);

      // Calculate total ascent
      let totalAscent = 0;
      for (let i = 1; i < elevations.length; i++) {
        const elevationGain = elevations[i] - elevations[i - 1];
        if (elevationGain > 0) {
          totalAscent += elevationGain; // Sum only positive elevation changes
        }
      }

      return { elevations, distances, totalAscent: Math.round(totalAscent) };
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getData();
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.getData();
    next();
  },
  watch: {
    "$route.params.CardId": {
      immediate: true,
      handler() {
        this.getData();
      },
    },
  },
  activated() {
    this.getData();
  },
};
</script>
<style>
.disabled-link {
  pointer-events: none;
  opacity: 0.6;
}

.badge-content {
  white-space: normal;
  word-wrap: break-word;
  text-align: right; /* Optional: Align text to the right */
  max-width: 350px; /* Optional: Set a max width for better control */
  border-radius: 4px;
  margin-bottom: 2px;
  font-size: 15px;
}

.badge-content2 {
  min-width: 180px;
  border: 1px solid #bfbcbc;
  white-space: normal;
  color: #626262;
  background-color: rgb(195 225 237 / 35%);
  word-wrap: break-word;
  text-align: left; /* Optional: Align text to the right */
  max-width: 150px; /* Optional: Set a max width for better control */
}

.color-box {
  width: 65px;
  height: 15px;
  display: inline-block;
  border: 1px solid #b9b8b8; /* Optional: Add a border for better visibility */
}

li.d-flex {
  flex-wrap: wrap;
  align-items: flex-start;
}

li.d-flex a {
  flex-shrink: 0;
  margin-right: 10px;
}

.image-wrap {
  width: 100%;
  height: 50vw;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.image-wrap img {
  width: 100%;
  animation: move 40s ease;
  /* Add infinite to loop. */

  -ms-animation: move 40s ease;
  -webkit-animation: move 40s ease;

  -moz-animation: move 40s ease;
  position: absolute;
}

.badge-primary {
  color: #000;
  background-color: #fbfbfb;
  padding: 10px;
  text-align: left;
  font-weight: 200;
}

.badge-secondary {
  color: #000;
  background-color: #ffffff;
  padding: 10px;
  text-align: left;
  font-weight: 600;
}

.text-dark {
  font-weight: 400;
}

.fixed-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Always three columns */
  gap: 10px; /* Adjust the gap as needed */
  padding: 0 15px; /* Add padding to fit within the viewport */
  box-sizing: border-box; /* Ensure padding is included in the total width */
  overflow-x: hidden; /* Hide horizontal overflow */
}

.fixed-grid-item {
  width: 100%; /* Ensure the grid items take full width of the grid column */
}

.service-item {
  width: 100%; /* Ensure the service items take full width of the grid column */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */

  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add transition for hover effect */
  min-height: 180px;
}

.service-item:hover {
  transform: translateY(-3px); /* Lift the box slightly */
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); /* Increase shadow for hover effect */
}
.service-item i {
  font-size: 2rem;
}

.service-item h5 {
  font-size: 1.25rem;
}

.service-item p {
  font-size: 1rem;
}

.card-info {
  display: flex;
  justify-content: space-around;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 80px;
}

.card-info div {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
  padding: 5px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add drop shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card-info div:hover {
  transform: translateY(-3px); /* Lift the box slightly */
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); /* Increase shadow for hover effect */
}

.card-info .grade {
  background-color: #3b82f6;
}

.card-info .length {
  background-color: #34d399;
}

.card-info .time {
  background-color: #fbbf24;
}

.card-info .type {
  background-color: #f87171;
}

.category-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  gap: 20px; /* Adjust the gap as needed */
}

.category-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  gap: 20px; /* Adjust the gap as needed */
  background-color: #ffffff; /* Light background color */
  border-radius: 8px; /* Rounded corners */
  padding: 10px; /* Padding inside the grid */
}

.category-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px; /* Padding for each item */
  background-color: #ffffff; /* White background for each item */
  border-radius: 4px; /* Rounded corners for each item */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Subtle shadow for each item */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effect */
}

.category-item:hover {
  transform: translateY(-3px); /* Lift the item slightly on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Increase shadow on hover */
}

.waymark {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px; /* Padding for each item */
  background-color: #ffffff; /* White background for each item */
  border-radius: 4px; /* Rounded corners for each item */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Subtle shadow for each item */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effect */
}

h6 {
  font-size: 30px;
}

.buttonsx {
  display: flex;
  justify-content: center;
  margin: 10px 0;
  gap: 1px;
}

buttonx {
  padding: 10px 20px;
  border: 1px solid #000;
  background: #fff;
  cursor: pointer;
  width: 300px;
}

buttonx.active {
  background: rgb(3, 167, 3);
  color: #fff;
}

.actions {
  display: flex;
  justify-content: space-between;
}

.tooltip {
  position: relative;
  display: inline-block;
  margin-left: 10px;
  opacity: 1;
}

.help-icon {
  font-size: 24px;
  cursor: pointer;
  top: 10px;
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: #838181;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 110%;
  left: 50%;
  margin-left: -250px;
  opacity: 1 !important;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
@keyframes move {
  0% {
    -webkit-transform-origin: bottom left;
    -moz-transform-origin: bottom left;
    -ms-transform-origin: bottom left;
    -o-transform-origin: bottom left;
    transform-origin: bottom left;
    transform: scale(1);
    -ms-transform: scale(1);
    /* IE 9 */

    -webkit-transform: scale(1);
    /* Safari and Chrome */

    -o-transform: scale(1);
    /* Opera */

    -moz-transform: scale(1);
    /* Firefox */
  }
  100% {
    transform: scale(1.5);
    -ms-transform: scale(1.5);
    /* IE 9 */

    -webkit-transform: scale(1.5);
    /* Safari and Chrome */

    -o-transform: scale(1.5);
    /* Opera */

    -moz-transform: scale(1.5);
    /* Firefox */
  }
}

@media (min-width: 1200px) {
  .about-text {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }
}

@media (max-width: 1200px) {
  .fixed-grid {
    grid-template-columns: repeat(
      3,
      minmax(0, 1fr)
    ); /* Maintain three columns on smaller screens */
  }
  .service-item i {
    font-size: 2rem;
  }
  .service-item h5 {
    font-size: 1.125rem;
  }
  .service-item p {
    font-size: 0.875rem;
  }
}

@media (max-width: 992px) {
  .fixed-grid {
    grid-template-columns: repeat(
      3,
      minmax(0, 1fr)
    ); /* Maintain three columns on smaller screens */
  }
  .service-item i {
    font-size: 2rem;
  }
  .service-item h5 {
    font-size: 1rem;
  }
  .service-item p {
    font-size: 0.75rem;
  }
}

@media (max-width: 768px) {
  .fixed-grid {
    grid-template-columns: repeat(
      3,
      minmax(0, 1fr)
    ); /* Maintain three columns on smaller screens */
  }
  .service-item i {
    font-size: 2rem;
  }
  .service-item h5 {
    font-size: 0.875rem;
  }
  .service-item p {
    font-size: 0.625rem;
  }

  .category-grid {
    grid-template-columns: 1fr; /* Switch to one column */
  }
}

@media (max-width: 576px) {
  .fixed-grid {
    grid-template-columns: repeat(
      3,
      minmax(0, 1fr)
    ); /* Maintain three columns on smaller screens */
  }
  .service-item i {
    font-size: 2rem;
  }
  .service-item h5 {
    font-size: 0.75rem;
  }
  .service-item p {
    font-size: 0.5rem;
  }
}
</style>
