import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import db from "./db";

// Function to inject the manifest
function injectDynamicManifest(manifest) {
  const manifestElement = document.createElement("link");
  manifestElement.rel = "manifest";

  const manifestBlob = new Blob([JSON.stringify(manifest)], {
    type: "application/json",
  });
  manifestElement.href = URL.createObjectURL(manifestBlob);

  document.head.appendChild(manifestElement);

  // Verify the manifest is injected
  console.log(
    "Manifest injected:",
    document.querySelector('link[rel="manifest"]')
  );
}

const tenantId = getTenantId();
//const manifest = getManifestForTenant(tenantId);
//injectDynamicManifest(manifest);

const installedTenantId = localStorage.getItem("tenantId");
if (installedTenantId != tenantId) {
  //domain has Changed, delete database and start over
  deleteDatabase();
  clearLocalStorage();
}

store.commit("setApiUrl", "https://live-api-v2.waywyser.com/api/");
store.commit("setCustomerId", tenantId);
localStorage.setItem("tenantId", tenantId);

createApp(App).use(store).use(router).mount("#app");

console.log(store.state.apiUrl);
getHeaderData();

async function getHeaderData() {
  const url = store.state.apiUrl + "VisitorCard/GetHeaders";
  const base64Credentials = btoa("Ian:Ennistymon1!");
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        CustomerId: tenantId,
        Authorization: `Basic ${base64Credentials}`,
      },
    });
    console.log(response.status);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const jsonData = await response.json();
    // Clear the existing records in the 'header' store
    await db.header.clear();
    await db.header.add(jsonData);
  } catch (error) {
    console.error("Failed to load and insert data:", error);
  }
}

function getTenantId() {
  const hostname = window.location.hostname;

  // Default tenantId
  let tenantId = 174;

  // Check for localhost explicitly
  if (hostname === "localhost") {
    return tenantId;
  }

  // Extract the subdomain from the hostname
  const subdomain = hostname.split(".")[0];

  // Map subdomain to tenantId
  switch (subdomain) {
    case "wicklow":
      tenantId = 174;
      break;
    case "ennistymon":
      tenantId = 41; //really duhallow
      break;
    case "duhallow":
      tenantId = 41; //really duhallow
      break;
    default:
      tenantId = 174; // or handle the default case as needed
  }

  return tenantId;
}

function getManifestForTenant(tenantId) {
  // Customize icons and other settings based on the tenantId
  let icons;
  let name;
  switch (tenantId) {
    case 174:
      name = "Wicklow";
      icons = [
        {
          src: "img/icons/wicklow_192.png",
          sizes: "192x192",
          type: "image/png",
        },
        {
          src: "img/icons/wicklow_512.png",
          sizes: "512x512",
          type: "image/png",
        },
      ];
      break;
    case 41:
      name = "Duhallow";
      icons = [
        {
          src: "img/icons/duhallow_192.png",
          sizes: "192x192",
          type: "image/png",
        },
        {
          src: "img/icons/duhallow_512.png",
          sizes: "512x512",
          type: "image/png",
        },
      ];
      break;
    default:
      name = "Unknown";
      icons = [
        {
          src: "img/icons/default_192.png",
          sizes: "192x192",
          type: "image/png",
        },
        {
          src: "img/icons/default_512.png",
          sizes: "512x512",
          type: "image/png",
        },
      ];
      break;
  }

  return {
    name: name,
    short_name: name,
    start_url: ".",
    display: "standalone",
    background_color: "#ffffff",
    theme_color: "#4DBA87",
    icons: icons,
  };
}

let deferredPrompt;

window.addEventListener("beforeinstallprompt", e => {
  // Prevent Chrome 67 and earlier from automatically showing the prompt
  e.preventDefault();
  // Stash the event so it can be triggered later.
  deferredPrompt = e;
});

// Assuming you have some installation prompt method triggered by user action or automatically
function promptInstallation() {
  if (this.$store.state.dataLoaded) {
    if (deferredPrompt) {
      deferredPrompt.prompt(); // Show the prompt
      deferredPrompt.userChoice.then(choiceResult => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt");
        } else {
          console.log("User dismissed the A2HS prompt");
        }
        deferredPrompt = null;
      });
    }
  } else {
    console.log("Data not ready, delaying installation");
    // Optionally, check again or set up a watcher on the Vuex state
  }
}

function deleteDatabase() {
  const dbName = "AppDatabase";

  const request = indexedDB.deleteDatabase(dbName);

  request.onsuccess = () => {
    console.log("Database deleted successfully");
    redirectHome();
  };

  request.onerror = event => {
    console.error("Error deleting database:", event);
  };

  request.onblocked = () => {
    console.warn("Database deletion blocked");
  };
}

function clearLocalStorage() {
  localStorage.clear();
}
