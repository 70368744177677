<template>
  <button class="btn btn-primary back-button" @click="goBack">← Back</button>
  <div class="container-fluid">
    <div class="container-fluid">
      <div v-if="card" class="video-container">
        <h1>{{ card.VideoTitle }}</h1>
        <p>{{ card.VideoDescription }}</p>
        <iframe
          v-if="videoUrl"
          width="1200px"
          height="800px"
          :src="videoUrl"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import db from "@/db"; // Ensure you have the correct path to your db

export default {
  props: ["CardId"],
  data() {
    return {
      card: null,
    };
  },
  computed: {
    videoUrl() {
      if (this.card && this.card.VideoLink) {
        const videoId = this.getYouTubeVideoId(this.card.VideoLink);
        return videoId ? `https://www.youtube.com/embed/${videoId}` : "";
      }
      return "";
    },
  },
  methods: {
    async getData() {
      try {
        const cardId = parseInt(this.$route.params.CardId);
        const cardArray = await db.cards
          .where("CardId")
          .equals(cardId)
          .toArray();
        this.card = cardArray[0];
      } catch (error) {
        console.error("Error fetching card data:", error);
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    getYouTubeVideoId(url) {
      if (!url) return null;
      const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
      const match = url.match(regExp);
      return match && match[2].length === 11 ? match[2] : null;
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
    "$route.params.CardId": {
      immediate: true,
      handler() {
        this.getData();
      },
    },
  },
};
</script>

<style>
.video-container {
  text-align: center;
  padding: 20px;
}

iframe {
  margin-top: 20px;
}
</style>
