<!-- src/layouts/MainLayout.vue -->
<template>
  <!-- Footer Start -->
  <div
    class="container-fluid bg-dark text-white-50 py-5 px-sm-3 px-lg-5"
    style="margin-top: 10px"
  >
    <div class="row pt-5">
      <div class="col-lg-3 col-md-6 mb-5">
        <a href="" class="navbar-brand">
          <h3 class="text-primary">
            <span class="text-white"> {{ titleMain }}</span> {{ titleLast }}
          </h3>
        </a>
        <p>
          {{ header.Blurb }}
        </p>
      </div>
      <div class="col-lg-3 col-md-6 mb-5">
        <h5 class="text-white text-uppercase mb-4" style="letter-spacing: 5px">
          USEFUL LINKS
        </h5>
        <div class="d-flex flex-column justify-content-start">
          <span class="text-white-50 mb-2"
            ><i class="fa fa-angle-right mr-2"></i>Emergency Services CALL
            112</span
          >
          <a class="text-white-50 mb-2" href="https://www.met.ie"
            ><i class="fa fa-angle-right mr-2"></i>Weather</a
          >
          <a
            class="text-white-50 mb-2"
            href="https://www.transportforireland.ie"
            ><i class="fa fa-angle-right mr-2"></i>Transport</a
          >
          <a
            v-if="isHomePage"
            class="text-primary pl-3 bin"
            href=""
            @click="deleteDatabase"
            style="margin-left: 8px"
          >
            Refresh All Data <i class="fa fa-trash"></i>
          </a>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mb-5">
        <h5 class="text-white text-uppercase mb-4" style="letter-spacing: 5px">
          Follow Us
        </h5>
        <div class="d-flex justify-content-center">
          <!-- Facebook -->
          <a
            v-if="header.Facebook"
            class="btn btn-outline-primary btn-square mr-2"
            :href="header.Facebook"
            target="_blank"
          >
            <i class="fab fa-facebook-f"></i>
          </a>
          <!-- Twitter -->
          <a
            v-if="header.Twitter"
            class="btn btn-outline-primary btn-square mr-2"
            :href="header.Twitter"
            target="_blank"
          >
            <i class="fab fa-twitter"></i>
          </a>
          <!-- LinkedIn -->
          <a
            v-if="header.LinkedIn"
            class="btn btn-outline-primary btn-square mr-2"
            :href="header.LinkedIn"
            target="_blank"
          >
            <i class="fab fa-linkedin-in"></i>
          </a>
          <!-- Instagram -->
          <a
            v-if="header.Instagram"
            class="btn btn-outline-primary btn-square mr-2"
            :href="header.Instagram"
            target="_blank"
          >
            <i class="fab fa-instagram"></i>
          </a>
          <!-- Youtube -->
          <a
            v-if="header.Youtube"
            class="btn btn-outline-primary btn-square mr-2"
            :href="header.Youtube"
            target="_blank"
          >
            <i class="fab fa-youtube"></i>
          </a>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 mb-5">
        <h5 class="text-white text-uppercase mb-4" style="letter-spacing: 5px">
          Contact Us
        </h5>
        <div v-html="formattedAddress"></div>
        <br />
        PH: {{ header.Phone }} <br />
        Email: {{ header.Email }}
      </div>
    </div>
  </div>
  <div
    class="container-fluid bg-dark text-white border-top py-4 px-sm-3 px-md-5"
    style="border-color: rgba(256, 256, 256, 0.1) !important"
  >
    <div class="row">
      <div class="col-lg-6 text-center text-md-left mb-3 mb-md-0"></div>
      <div class="col-lg-6 text-center text-md-right">
        <p class="m-0 text-white-50">
          Powered by <a href="https://waywyser.com">Waywyser</a>
        </p>
      </div>
    </div>
  </div>
  <!-- Footer End -->

  <!-- Back to Top -->
  <a href="#" class="btn btn-lg btn-primary btn-lg-square back-to-top"
    ><i class="fa fa-angle-double-up"></i
  ></a>
</template>

<script>
import db from "@/db";

export default {
  name: "Header",
  created() {
    // Fetch the tenantId from local storage when the component is created
    this.tenantId = localStorage.getItem("tenantId");
  },
  data() {
    return {
      tenantId: "", // Initial state
      header: {},
    };
  },
  computed: {
    titleMain() {
      if (this.header && this.header.Title) {
        // Split the title into words
        const words = this.header.Title.split(" ");
        // If there is more than one word, return all except the last one
        return words.length > 1
          ? words.slice(0, -1).join(" ")
          : this.header.Title;
      }
      return ""; // Return an empty string if header.Title is not defined
    },
    titleLast() {
      if (this.header && this.header.Title) {
        // Split the title into words
        const words = this.header.Title.split(" ");
        // Return the last word if there is more than one
        return words.length > 1 ? words[words.length - 1] : "";
      }
      return ""; // Return an empty string if header.Title is not defined
    },
    isHomePage() {
      return this.$route.name === "home"; // Ensure 'home' is the name of your homepage route
    },
    formattedAddress() {
      return this.header.Address
        ? this.header.Address.replace(/\n/g, "<br/>")
        : "";
    },
  },
  async mounted() {
    try {
      // Fetch General Header data

      if (!this.tenantId) {
        throw new Error("Tenant ID not found in localStorage.");
      }

      var headerArray = await db.header
        .where("CustomerId")
        .equals(parseInt(this.tenantId))
        .toArray();

      if (headerArray.length > 0) {
        this.header = headerArray[0];
        console.log("Header:", this.header); // Log the header object

        // Log the specific value of BlogUrl
        console.log("header.BlogUrl:", this.header.BlogURL);

        // Log the value of isBlogUrlValid after header is set
        console.log("isBlogUrlValid:", this.isBlogUrlValid);
      } else {
        console.warn("No header data found for the given tenant ID.");
      }
    } catch (error) {
      console.error("Error fetching header data:", error);
    }
  },
  methods: {
    deleteDatabase() {
      const dbName = "AppDatabase";

      const request = indexedDB.deleteDatabase(dbName);

      request.onsuccess = () => {
        console.log("Database deleted successfully");
        this.redirectHome();
      };

      request.onerror = event => {
        console.error("Error deleting database:", event);
      };

      request.onblocked = () => {
        console.warn("Database deletion blocked");
      };
    },
    redirectHome() {
      this.$router.push({ name: "home" });
    },
  },
};
</script>
<style scoped>
.footer {
  background-color: #f8f9fa;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 0;
}
</style>
