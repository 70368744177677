<template>
  <!-- Blog Start -->
  <div class="container-fluid py-2">
    <div class="row">
      <div class="col-lg-12" style="padding: 0px">
        <h3>Trails</h3>
      </div>
    </div>

    <div class="container py-5" style="padding-top: 10px !important">
      <div class="row">
        <div class="col-lg-12">
          <input
            type="text"
            class="form-control mb-4"
            placeholder="Search Walks..."
            v-model="searchQuery"
          />

          <div class="filters">
            <div class="form-group">
              <label for="gradeFilter">Grade</label>
              <select
                id="gradeFilter"
                class="form-control"
                v-model="gradeFilter"
              >
                <option value="">All</option>
                <option value="Strenuous">Strenuous</option>
                <option value="Moderate">Moderate</option>
                <option value="Easy">Easy</option>
              </select>
            </div>

            <div class="form-group">
              <label for="typeFilter">Type</label>
              <select id="typeFilter" class="form-control" v-model="typeFilter">
                <option value="">All</option>
                <option value="Linear">Linear</option>
                <option value="Loop">Loop</option>
              </select>
            </div>

            <div class="form-group">
              <label for="lengthFilter">Length (km)</label>
              <div class="slider-container">
                <div
                  class="slider-value"
                  :style="{ left: sliderValuePosition }"
                >
                  {{ lengthFilter }} km
                </div>
                <input
                  type="range"
                  id="lengthFilter"
                  class="form-control"
                  v-model="lengthFilter"
                  :min="0"
                  :max="maxLength"
                  step="1"
                  @input="updateSliderValuePosition"
                />
              </div>
            </div>
          </div>

          <div class="row pb-3">
            <div
              class="col-md-6 mb-4 pb-2"
              v-for="card in filteredCards"
              :key="card.id"
            >
              <router-link
                :to="'/information/' + card.CardId"
                class="col-md-6 mb-4 pb-2"
                style="text-decoration: none; color: inherit"
                @click.native="saveScrollPosition"
              >
                <div class="blog-item">
                  <div class="position-relative">
                    <ImageComponent
                      v-if="card.CoverImage"
                      :imageId="card.CoverImage"
                    ></ImageComponent>
                    <div class="card-info">
                      <div class="grade">
                        <small class="text-white text-uppercase">
                          <i
                            class="fa fa-shoe-prints"
                            style="font-size: 1.4rem"
                          ></i
                          ><span style="margin-left: 5px">{{
                            card.Grading
                          }}</span>
                        </small>
                      </div>
                      <div class="time">
                        <small class="text-white text-uppercase">
                          <i class="fa fa-clock" style="font-size: 1.4rem"></i
                          ><span style="margin-left: 5px">{{
                            formatTime(card.Duration)
                          }}</span>
                        </small>
                      </div>
                      <div class="length">
                        <small class="text-white text-uppercase">
                          <i class="fa fa-map-pin" style="font-size: 1.4rem"></i
                          ><span style="margin-left: 5px"
                            >{{ card.Distance }} km</span
                          >
                        </small>
                      </div>

                      <div class="type">
                        <small class="text-white text-uppercase">
                          <i class="fa fa-sign" style="font-size: 1.4rem"></i
                          ><span style="margin-left: 5px">{{
                            card.ShapeName
                          }}</span>
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="bg-white p-4">
                    <div class="card-name">
                      <a
                        class="text-primary text-uppercase text-decoration-none"
                        >{{ card.Name }}</a
                      >
                    </div>
                    <div class="card-sub-name">
                      <span style="font-size: 16px">{{ card.SubName }}</span>
                    </div>
                    <div class="d-flex justify-content-between">
                      <div class="card-distance-from-location">
                        <b>Distance From Me </b>
                        <span style="font-size: 14px"
                          >{{
                            card.DistanceFromCurrentLocation.toFixed(2)
                          }}
                          km</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4 col-md-6 mb-4">
          <router-link
            v-if="card"
            :to="'/information/' + card.CardId"
            style="text-decoration: none; color: inherit"
          >
            <div class="service-item bg-white text-center mb-2 py-5 px-4">
              <i class="fa fa-2x fa-question-circle mx-auto mb-4"></i>
              <h5 class="mb-2">INFORMATION</h5>
              <p class="m-0">Get Information about {{ card.Name }}</p>
            </div>
          </router-link>
        </div>

        <!--
        <div class="col-lg-4 col-md-6 mb-4">
          <div
            class="service-item bg-white text-center mb-2 py-5 px-4"
            @click="
              openGoogleMaps(card.StartLocationLat, card.StartLocationLng)
            "
            style="cursor: pointer"
          >
            <i class="fa fa-2x fa-route mx-auto mb-4"></i>
            <h5 class="mb-2">TRAIL HEAD</h5>
            <p class="m-0">Open in Google Maps</p>
            <br />
          </div>
        </div>
-->

        <div class="col-lg-4 col-md-6 mb-4">
          <router-link
            v-if="card"
            :to="'/map/' + card.CardId"
            style="text-decoration: none; color: inherit"
          >
            <div class="service-item bg-white text-center mb-2 py-5 px-4">
              <i class="fa fa-2x fa-play mx-auto mb-4"></i>
              <h5 class="mb-2">START NOW</h5>
              <p class="m-0">
                When at the trail head, Click here to log your activity
              </p>
            </div>
          </router-link>
        </div>

        <div class="col-lg-4 col-md-6 mb-4">
          <router-link
            v-if="card"
            :to="'/elevation/' + card.CardId"
            style="text-decoration: none; color: inherit"
          >
            <div class="service-item bg-white text-center mb-2 py-5 px-4">
              <i class="fa fa-2x fa-chart-area mx-auto mb-4"></i>
              <h5 class="mb-2">ROUTE ASCENT PROFILE</h5>
              <p class="m-0">
                See the elevation profile of {{ card.Name }} walk
              </p>
            </div>
          </router-link>
        </div>

        <div class="col-lg-4 col-md-6 mb-4">
          <router-link
            v-if="card"
            :to="'/services/' + card.CardId"
            style="text-decoration: none; color: inherit"
          >
            <div class="service-item bg-white text-center mb-2 py-5 px-4">
              <i class="fa fa-2x fa-concierge-bell mx-auto mb-4"></i>
              <h5 class="mb-2">NEAR BY SERVICES</h5>
              <p class="m-0">Services Near {{ card.Name }}</p>
            </div>
          </router-link>
        </div>
        <div class="col-lg-4 col-md-6 mb-4">
          <router-link
            v-if="card"
            :to="'/pois/' + card.CardId"
            style="text-decoration: none; color: inherit"
          >
            <div class="service-item bg-white text-center mb-2 py-5 px-4">
              <i class="fa fa-2x fa-map-marker mx-auto mb-4"></i>

              <h5 class="mb-2">NEAR BY POIs</h5>
              <p class="m-0">Points Of Interest Near {{ card.Name }}</p>
            </div>
          </router-link>
        </div>

        <div class="col-lg-4 col-md-6 mb-4" v-if="card && card.VideoLink">
          <router-link
            v-if="card"
            :to="'/video/' + card.CardId"
            style="text-decoration: none; color: inherit"
          >
            <div class="service-item bg-white text-center mb-2 py-5 px-4">
              <i class="fa fa-2x fa-map-marker mx-auto mb-4"></i>

              <h5 class="mb-2">Video Overview</h5>
              <p class="m-0">See a video of {{ card.Name }}</p>
            </div>
          </router-link>
        </div>

        <div class="col-lg-4 col-md-6 mb-4">
          <router-link
            v-if="card"
            :to="'/issue/' + card.CardId"
            style="text-decoration: none; color: inherit"
          >
            <div class="service-item bg-white text-center mb-2 py-5 px-4">
              <i class="fa fa-2x fa-map-marker mx-auto mb-4"></i>

              <h5 class="mb-2">Report Issue</h5>
              <p class="m-0">Send us the details</p>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <!-- Blog End -->
</template>
<script>
import Vue from "vue";
import db from "@/db"; // Path to your Dexie database setup
import ImageComponent from "@/components/ImageComponent.vue";

export default {
  name: "YourComponentName",
  components: {
    ImageComponent,
  },
  data() {
    return {
      searchQuery: "",
      cards: [],
      gradeFilter: "",
      typeFilter: "",
      lengthFilter: 0,
      maxLength: 0,
      currentLat: null,
      currentLng: null,
      sliderValuePosition: "0%",
    };
  },
  computed: {
    filteredCards() {
      const cardsWithDistance = this.cards.map(card => {
        const distance = this.getDistanceFromLatLonInKm(
          this.currentLat,
          this.currentLng,
          card.StartLocationLat,
          card.StartLocationLng
        );
        return { ...card, DistanceFromCurrentLocation: distance };
      });

      console.log(
        "Filters - searchQuery:",
        this.searchQuery,
        "gradeFilter:",
        this.gradeFilter,
        "typeFilter:",
        this.typeFilter,
        "lengthFilter:",
        this.lengthFilter
      );

      let result = cardsWithDistance.filter(card => {
        const matchesSearchQuery = card.Name.toLowerCase().includes(
          this.searchQuery.toLowerCase()
        );
        const matchesGrade =
          !this.gradeFilter || card.Grading === this.gradeFilter;
        const matchesType =
          !this.typeFilter || card.ShapeName === this.typeFilter;
        const matchesLength =
          parseFloat(card.Distance) <= parseFloat(this.lengthFilter);

        console.log(
          "Card:",
          card.Name,
          "Distance:",
          card.Distance,
          "matchesSearchQuery:",
          matchesSearchQuery,
          "matchesGrade:",
          matchesGrade,
          "matchesType:",
          matchesType,
          "matchesLength:",
          matchesLength
        );

        return (
          matchesSearchQuery && matchesGrade && matchesType && matchesLength
        );
      });

      result.sort(
        (a, b) => a.DistanceFromCurrentLocation - b.DistanceFromCurrentLocation
      );

      return result;
    },
  },
  watch: {
    maxLength(newMaxLength) {
      if (newMaxLength > 0) {
        this.$nextTick(() => {
          this.lengthFilter = newMaxLength;
          this.updateSliderValuePosition();
        });
      }
    },
    lengthFilter() {
      this.updateSliderValuePosition();
    },
  },
  methods: {
    saveScrollPosition() {
      const scrollPosition = window.scrollY;
      sessionStorage.setItem("scrollPosition2", scrollPosition);
    },
    getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
      const R = 6371; // Radius of the earth in kilometers
      const dLat = this.deg2rad(lat2 - lat1);
      const dLon = this.deg2rad(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.deg2rad(lat1)) *
          Math.cos(this.deg2rad(lat2)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = R * c; // Distance in km
      return distance;
    },
    deg2rad(deg) {
      return deg * (Math.PI / 180);
    },
    async fetchCards() {
      this.cards = await db.cards.where("IsActive").equals("True").toArray();
      this.maxLength =
        Math.max(...this.cards.map(card => parseFloat(card.Distance))) + 1;
      console.log("Max Length: " + this.maxLength); // Ensure maxLength is set correctly
    },
    updateSliderValuePosition() {
      const input = this.$refs.slider;
      const value = (this.lengthFilter / this.maxLength) * 100;
      this.sliderValuePosition = `calc(${value}% - ${(8 * value) / 100}px)`;
    },
    formatTime(minutes) {
      const days = Math.floor(minutes / 1440);
      const hours = Math.floor((minutes % 1440) / 60);
      const mins = minutes % 60;
      let formattedTime = "";

      if (days > 0) {
        formattedTime += `${days} day${days > 1 ? "s" : ""}`;
      }

      if (hours > 0) {
        if (formattedTime) formattedTime += " ";
        formattedTime += `${hours} hr${hours > 1 ? "s" : ""}`;
      }

      if (mins > 0) {
        if (formattedTime) formattedTime += " ";
        formattedTime += `${mins} min${mins > 1 ? "s" : ""}`;
      }

      return formattedTime || "0 mins";
    },
  },
  mounted() {
    // Restore the scroll position if it exists
    const savedPosition = sessionStorage.getItem("scrollPosition2");
    //console.log("scrlPos " + savedPosition);
    if (savedPosition) {
      // Use nextTick to ensure the DOM is fully loaded before scrolling
      this.$nextTick(() => {
        setTimeout(() => {
          window.scrollTo(0, parseInt(savedPosition, 10));
        }, 100);
        sessionStorage.removeItem("scrollPosition2"); // Clean up the saved position
      });
    } else {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    }

    navigator.geolocation.getCurrentPosition(
      position => {
        this.currentLat = position.coords.latitude;
        this.currentLng = position.coords.longitude;
      },
      error => {
        console.error("Geolocation error:", error);
        // Handle location error (e.g., user denied location access)
      }
    );

    this.fetchCards();
    this.updateSliderValuePosition();
  },
};
</script>

<style scoped>
.blog-item img {
  width: 540px;
  height: 360px;
  object-fit: cover; /* This will cover the area, cropping the image as needed. Use 'contain' to fit the whole image without cropping */
}

.sunny-bright {
  filter: brightness(110%) contrast(110%) saturate(105%);
}

.blog-item {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
}
.blog-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.position-relative {
  position: relative;
}
.card-distance {
  position: absolute;
  top: 8px;
  left: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
}

.card-info {
  display: flex;
  justify-content: space-around;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 15%;
}

.card-info div {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
  padding: 5px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add drop shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card-info div:hover {
  transform: translateY(-3px); /* Lift the box slightly */
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); /* Increase shadow for hover effect */
}

.card-info .grade {
  background-color: #3b82f6;
}

.card-info .length {
  background-color: #34d399;
}

.card-info .time {
  background-color: #fbbf24;
}

.card-info .type {
  background-color: #f87171;
}

.bg-white {
  background-color: white;
}
.p-4 {
  padding: 16px;
}
.card-name {
  font-size: 1.25rem;
  font-weight: bold;
}
.card-sub-name {
  font-size: 1rem;
  color: gray;
}
.card-grading {
  font-size: 0.875rem;
  color: green;
}
.card-distance-from-location {
  font-size: 0.875rem;
  color: green;
}
.d-flex {
  display: flex;
  align-items: center;
}
.justify-content-between {
  justify-content: space-between;
}

.d-flex.justify-content-between {
  background-color: whitesmoke;
  padding: 10px;
  margin-top: 10px;
}

.filters {
  display: flex;
  gap: 1rem;
}

.filters .form-group {
  flex: 1;
}

h6,
.h6 {
  font-size: 1rem;
  font-weight: 1200 !important;
  color: #666666;
}

.slider-container {
  position: relative;
  width: 100%;
  top: 10px;
}

.slider-value {
  position: absolute;
  top: -20px;
  background-color: #3b82f688;
  color: #fff;
  padding: 3px 6px;
  min-width: 70px;
  border-radius: 4px;
  transform: translateX(-50%);
}

input[type="range"] {
  width: 100%;
}
</style>
